const requiredMessage = name => 'Os campos com * são obrigatórios'

export const validate = values => {
  const errors = {
    required: '',
    general: []
  }

  if (!values.credentials) {
    errors.required = requiredMessage()
    return errors
  }

  if (!values.credentials.cpf_cnpj) {
    errors.required = requiredMessage()
  } else if (values.credentials.cpf_cnpj.length < 14) {
    errors.general.push('CPF inválido!')
  }

  if (!values.credentials.password) {
    errors.required = requiredMessage()
  }

  return errors
}
